<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >AskSuite</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <p style="margin-bottom:20px!important;" >A AskSuite possui um chat bot que é utilizado nos sites dos clientes.</p>
                    <p style="margin-bottom:20px!important;">A parceria se dá atraves de uma API criada que localiza o web checkin do hospede e devolve para a asksuite o link desse web check in.</p>
                </div>
                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h3 stye="margin-bottom:20px!important;">Exemplo de utilização.</h3>
                    <p style="margin-bottom:20px!important;">Link que o parceiro usa para chamar a API ( metodo POST ): https://apiparceiros.fd.economysoftware.com.br/buscarHospedesReserva</p>
                    <p style="margin-bottom:20px!important;">No <b>header</b> da requisição ask suite precisa passar de forma fixa o campo de autorização a seguir.</p>
                    <p style="margin-bottom:20px!important;">Authorization: 7ea9c66bd333ffadfdfa63e719efc9b264617bbc67c1197ebecbec475f6eaa8863e8a5eeab796043</p>
                    <p style="margin-bottom:20px!important;"><b>Obs</b>: O campo de Authorization no <b>header</b>, será sempre o mesmo para Asksuites, para todos os clientes.</p>
                    <p style="margin-bottom:20px!important;">No body da requisação deve-se passar o objeto a seguir:<br><br>
                        {<br>
  "nome":"Isabella",<br>
  "sobrenome": "Rocha França Longo Staino",<br>
  "data_checkin": "2024-06-15",<br>
  "data_checkout":"2024-06-16",<br>
  "id_hotel": <b>236</b> ,<br>
  "localizador":9284<br>
}<br>
                         </p>

                    <p style="margin-bottom:20px!important;">A API retornará para a ASKSuite o link do web check in, se o mesmo for localizado: Ex: https://webcheckin.fd.economysoftware.com.br/webcheckinhospede/xxx/x .</p>
                    
                    <p style="margin-bottom:20px!important;">A configuração da parceria é feita pelo proprio cliente através das telas do AskSuite, bastando a Economy simplesmente informar ao cliente qual é o id do cliente. Esse id pode ser localizado na tela de clientes na Central de Controle.</p>

                    <p style="margin-bottom:20px!important;"><b>Localização do serviço</b>: Servidor do Ficha.</p>
                    <p style="margin-bottom:20px!important;"><b>Nome do repositorio</b>: back_api_parceiros.</p>
                </div>
               





            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Parceiros',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style scoped>


    .paragrafo {
        margin-bottom:20px!important;
    }
</style>